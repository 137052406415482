/** @jsx jsx */
import { Col, Container, Row } from "react-bootstrap"
import { jsx } from '@emotion/react'
import { Fragment } from "react"
import Page404 from "@/images/lp/home/404.svg"

import { 
  politicaPage,
}  from '../assets/styles/Other.styles'

const NotFoundContent = () => {
  return (
    <Fragment>
      <section css={politicaPage}>
        <Container>
          <Row>
            <Col lg={12} md={12} sm={12}>
              <h2>Política de Privacidade</h2>
              <p>Esta página é utilizada para informar os visitantes do site sobre as nossas políticas relativas à coleta, utilização e divulgação de informações pessoais, caso alguém decida utilizar o nosso Serviço.</p>
              <p>Se optar por utilizar o nosso Serviço, então concorda com a coleta e utilização de informações em relação a esta política. As Informações Pessoais que recolhemos são utilizadas para fornecer e melhorar os nossos serviços. Não utilizaremos ou partilharemos as suas informações com ninguém, exceto conforme descrito nesta Política de Privacidade.</p>
              <p>Os termos utilizados nesta Política de Privacidade têm os mesmos significados que nos nossos Termos e Condições, que podem ser acessados em Fractal ID, salvo definição em contrário nesta Política de Privacidade.</p>
              <h3>Coleta e utilização de informações</h3>
              <p>Para uma melhor experiência, podemos pedir-lhe que nos forneça determinadas informações de identificação pessoal, incluindo, mas não se limitando a, nome, e-mail, localização, informações sobre educação e informações médicas. As informações que solicitamos serão retidas por nós e utilizadas conforme descrito na presente política de privacidade.</p>
              <p>Nossos sites utilizam serviços de terceiros que podem recolher informações utilizadas para identificar o usuário.</p>
              <h3>Registro de Dados</h3>
              <p>Queremos informá-lo de que, sempre que utilizar o nosso Serviço, em caso de erro na aplicação, recolhemos dados e informações (através de produtos de terceiros) no seu telefone, denominados Dados de registo. Estes Dados de Registo podem incluir informações como o endereço de Protocolo de Internet ("IP") do seu dispositivo, o nome do dispositivo, a versão do sistema operativo, a configuração da aplicação quando utiliza o nosso Serviço, a hora e a data da sua utilização do Serviço e outras estatísticas.</p>
              <h3>Cookies</h3>
              <p>Os cookies são ficheiros com uma pequena quantidade de dados que são normalmente utilizados como identificadores anônimos únicos. Estes são enviados para o seu navegador a partir do site que visita e são armazenados na memória interna do seu dispositivo.</p>
              <p>Podemos utilizar códigos e bibliotecas de terceiros que utilizam "cookies" para recolher informações e melhorar os seus serviços. O utilizador tem a opção de aceitar ou recusar estes cookies e de saber quando um cookie está a ser enviado para o seu dispositivo. Se optar por recusar os nossos cookies, poderá não conseguir utilizar ou visualizar algumas partes deste Serviço.</p>
              <h3>Prestadores de Serviço</h3>
              <p>Podemos recorrer empresas e indivíduos terceiros devido às seguintes razões:</p>
              <p>- Para facilitar o nosso Serviço;</p>
              <p>- Para fornecer o Serviço em nosso nome;</p>
              <p>- Para efetuar serviços relacionados com o Serviço; ou</p>
              <p>- Para nos ajudar a analisar a forma como o nosso Serviço é utilizado.</p>
              <p>Queremos informar os visitantes de que estes terceiros têm acesso às suas Informações Pessoais. O motivo é a execução das tarefas que lhes são atribuídas em nosso nome. No entanto, são obrigados e comprometidos a não divulgar ou utilizar as informações para qualquer outro fim.</p>
              <h3>Segurança</h3>
              <p>Valorizamos a sua confiança ao nos fornecer as suas informações pessoais. Nosso compromisso é utilizar meios comercialmente aceitáveis para proteger seus dados. Mas lembre-se que nenhum método de transmissão através da Internet, ou método de armazenamento eletrônico é 100% seguro, e não podemos garantir a sua segurança absoluta.</p>
              <h3>Links para outros sites</h3>
              <p>Este Serviço pode conter ligações a outros sites. Se clicar num link de terceiros, será direcionado para esse site. Sites externos não são operados por nós. Por isso, aconselhamos vivamente que reveja a Política de Privacidade desses sites. Não temos qualquer controle e não assumimos qualquer responsabilidade pelo conteúdo, políticas de privacidade ou práticas de quaisquer sites ou serviços de terceiros.</p>
              <h3>Privacidade das crianças</h3>
              <p>Não recolhemos intencionalmente informações pessoais identificáveis de crianças com menos de 13 anos. No caso de descobrirmos que uma criança com menos de 13 anos nos forneceu informações pessoais, estas serão imediatamente eliminadas dos nossos servidores. Se for pai ou tutor e tiver conhecimento de que o seu filho nos forneceu informações pessoais, contacte-nos para que possamos tomar as medidas necessárias.</p>
              <h3>Alterações a esta Política de privacidade</h3>
              <p>A nossa Política de Privacidade pode ser atualizada periodicamente. Assim, aconselhamos o visitante a rever periodicamente esta página para verificar se houve alterações. Iremos notificar quaisquer alterações, publicando a nova Política de Privacidade nesta página. Estas alterações entram em vigor imediatamente após a sua publicação nesta página.</p>
              <h3>Fale com a gente</h3>
              <p>Se tiver alguma dúvida ou sugestão sobre a nossa Política de Privacidade, não hesite em entrar em contato com a gente.</p>
              <p>Esta página de política de privacidade foi criada em <a href="https://www.privacypolicytemplate.net/">privacypolicytemplate.net</a> e modificada/gerada pelo <a href="https://app-privacy-policy-generator.firebaseapp.com/">App Privacy Policy</a> .</p>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
    
  );
};

export default NotFoundContent;
